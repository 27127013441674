<template>
  <div v-if="isShowMsg" class="chat-list-item" :class="{ 'chat-msg-color': item.deleted }">
    <div class="ctrl-pnl-msg">
      <ReplyBtn v-if="isPast" :id="item.id" />
      <AdminMsg v-if="isAdminMsg" :id="item.id" :is-deleted="item.deleted" />
    </div>
    <aside class="mts-avatar__photo">
      <img v-if="isVisibleAvatar" :src="imgUrl" alt="" class="mts-avatar__image" />
      <LockPerson v-if="item.is_anon" />
    </aside>
    <section>
      <ReplyMsg v-if="replyMsg" :reply-msg="replyMsg" />
      <span class="chat-fio">{{ fullname }}</span>
      <span class="chat-msg">
        <span v-if="item.is_question" class="chat-msg-question">Вопрос:</span>
        <span class="chat-msg-text" v-if="isLink">{{ item.text }}</span>
        <span class="chat-msg-text" v-else v-html="searchAddLink(item.text)" />
      </span>
      <span class="chat-msg-footer">
        <span class="chat-time">{{ getTime(item.time) }}</span>
        <ReactionPopup v-if="isPopup" :id="item.id" :key="redraw" @on-redraw="redraw += 1" />
        <ReactionPanel
          :id="item.id"
          :reactions="reactions"
          :selfReaction="selfReaction"
          :username="username"
        />
      </span>
    </section>
  </div>
</template>

<script>
import { URL } from '@/const'
import LockPerson from '@/pic/svg/theater-comedy'
import ReactionPopup from './comp/reaction-popup/reaction-popup'
import ReactionPanel from './comp/reaction-panel/reaction-panel'
import AdminMsg from './comp/admin/admin.vue'
import ReplyBtn from './comp/reply-btn/reply-btn.vue'
import ReplyMsg from './comp/reply-msg/reply-msg.vue'
import { getDateViewToString, searchAddLink } from '../../../../func'
import { getJsonToString, getReactions } from './func'
export default {
  name: 'chat-list-item',
  components: { LockPerson, ReactionPanel, ReactionPopup, AdminMsg, ReplyBtn, ReplyMsg },
  props: {
    item: { type: Object },
    replyMsg: { type: Object },
    username: { type: String },
    isAdmin: { type: Boolean },
    isPast: { type: Boolean }
  },
  computed: {
    isLink() {
      return this.item.text.indexOf('http') === -1
    },
    isVisibleAvatar() {
      if (this.item.is_anon) return false
      return !!this.item.avatar
    },
    isPopup() {
      if (!this.isPast) return false
      return !this.item.deleted
    },
    isShowMsg() {
      if (this.isAdmin) return true
      return !this.item.deleted
    },
    isAdminMsg() {
      if (!this.isPast) return false
      return this.isAdmin
    }
  },
  data() {
    return {
      reactions: {},
      selfReaction: '',
      imgUrl: '',
      fullname: '',
      redraw: 0
    }
  },
  methods: {
    searchAddLink(text) {
      return searchAddLink(text)
    },
    getTime(date) {
      return getDateViewToString(date)
    },
    initReaction(msg) {
      const item = getJsonToString(msg.reactions)
      this.selfReaction = item[this.username] ? item[this.username] : ''
      this.reactions = getReactions(item)
    }
  },
  watch: {
    item: {
      handler(newItem) {
        this.initReaction(newItem)
      },
      deep: true
    }
  },
  created() {
    this.initReaction(this.item)

    this.imgUrl = `${URL.ISSO_AVATAR}/${this.item.avatar}`
    this.fullname = this.item.is_anon ? 'Анонимное сообщение' : this.item.fullname
  }
}
</script>

<style lang="sass">
@import './styles/index.scss'
</style>
